import { defineStore, acceptHMRUpdate } from 'pinia';

export const useContentStore = defineStore('content', {
  state: () => ({
    sidebarRightContent: '',
    feature: {},
    statusMarkdown: '',
    headerMarkdown: '',
  }),
  getters: {},
  actions: {},
  persist: true,
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useContentStore, import.meta.hot));
}
